button {
  &.submit-btn {
    @apply flex justify-center w-full mt-[3rem] p-[2rem] text-[1.8rem] text-center text-primary-white rounded-2xl cursor-pointer bg-gradient-to-r from-gradients-dialogBtnStart to-gradients-dialogBtnStop;

    &-small {
      @apply w-auto mt-[1.5rem] p-[1rem];
    }

    &:hover {
      @apply bg-gradient-to-r from-primary-gradientHover-start to-primary-gradientHover-stop;
    }

    .p-button-icon {
      @apply text-[2rem] mr-[1rem];
    }
  }

  &:focus {
    @apply shadow-none;
  }
}

.p-button, .p-splitbutton {
  &.action, &.cancel, &.secondary-fill {
    @apply flex justify-center border rounded-[1rem] h-[4rem] w-[15rem] text-[1.6rem];
  }

  &.action {
    @apply border-secondary-blue text-secondary-blue mr-[2.3rem] ;
  }

  &.cancel {
    @apply border-secondary-red text-secondary-red mr-[2.3rem] ;
  }

  &.secondary-fill {
    @apply border-0 text-primary-white bg-gradient-to-r from-gradients-dialogBtnStart to-gradients-dialogBtnStop;
  }

  &.small {
    @apply w-[4rem] h-[4rem] mr-0;
  }
}
