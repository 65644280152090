@import 'primeng/resources/themes/lara-dark-blue/theme.css';
@import '_variables.css';

html {
  @apply bg-primary;
}

.text-gradient {
  @apply bg-gradient-to-r from-primary-gradient-start to-primary-gradient-stop;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
