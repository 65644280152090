.p-orderlist {
  @apply max-w-[65rem];

  &-controls {
    @apply cursor-pointer;
    .p-button {
      @apply w-[3rem] h-[3rem] mb-[1rem] border border-[#27272a] bg-[#27272a] cursor-pointer;
      transition: opacity 0.2s, outline-color 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      color: #d4d4d8;
    }
  }

  &-list-container {
    @apply bg-primary p-0;
  }

  &-item {
    @apply px-0 py-[1.5rem];
  }
}
