.p-timeline {
  &.points-timeline {
    @apply mt-[1rem] mb-[5rem];
    .p-timeline-event {
      &:not(:last-child) {
        @apply min-h-[10rem];
      }

      &-content {
        @apply text-[1.2rem];
        div {
          @apply flex items-center mb-[.5rem];
        }
      }

      .p-timeline-event-opposite {
        @apply flex-grow-0 min-w-[5rem];
      }

      .timeline {
        &-step {
          @apply flex items-center justify-center rounded-full w-[4rem] h-[4rem] text-[1.2rem] text-primary;

          &.state-done {
            @apply bg-secondary-green;
          }

          &.state-to_approve {
            @apply bg-secondary-yellow;
          }

          &.state-ready {
            @apply bg-secondary-blue;
          }
        }
      }
    }
  }
}
