.leaflet-popup-content {
  @apply text-[1.4rem];

  h3 {
    @apply text-[1.8rem] font-bold mb-[.5rem];
  }

  .bold {
    @apply font-bold;
  }

  p {
    @apply my-[.3rem]
  }
}
