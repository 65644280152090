.p-dialog {
  @apply rounded-[1rem];

  &-header {
    @apply px-[4rem] pt-[3.5rem] pb-0 bg-primary;

    &-icons {
      @apply absolute top-[1.3rem] right-[1.6rem] w-[2.4rem] h-[2.4rem];

      svg {
        @apply w-[1.2rem] h-[1.2rem];
      }
    }
  }

  &-content {
    @apply px-[4rem] pb-[3.5rem] bg-primary;

  }

  &.panel-dialog {
    .p-dialog {
      &-header {
        @apply px-[2.4rem] py-[2rem] border-b border-b-grayAlpha30 text-[1.8rem] text-secondary-blue;
      }

      &-content {
        @apply pt-[3rem];

        .submit-btn {
          @apply mt-0;
        }
      }

    }
  }

  &.without-padding {
    .p-dialog-content {
      @apply p-0 rounded-[1rem];
    }
  }

  &.p-confirm-dialog {
    .p-dialog-header {
      @apply px-[2.4rem] py-[2rem] border-b border-b-grayAlpha30 text-[1.8rem] text-secondary-blue;
    }

    .p-dialog-content {
      @apply text-[1.8rem] p-[2rem];
    }

    .p-dialog-footer {
      @apply flex flex-row-reverse bg-primary;

      button {
        @apply w-[5rem];
      }
    }
  }
}
