.p-toast {
  @apply w-[40rem] font-medium;

  &-message {
    @apply text-[1.4rem];
  }
  &-error {
    @apply bg-secondary-red opacity-15;
  }

  &-summary {
    @apply text-[2rem];
  }
}
