.p-orderlist {
  @apply w-2/3;

  &-controls {
    @apply cursor-pointer;
    .p-button {
      @apply w-[3rem] h-[3rem] mb-[1rem] border border-[#27272a] bg-[#27272a] cursor-pointer;
      transition: opacity 0.2s, outline-color 0.2s, background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      color: #d4d4d8;
    }
  }

  &-list-container {
    @apply bg-primary;

    li:not(:first-child) {
      @apply pt-[1rem];
    }

    li:not(:last-child) {
      @apply border-b border-b-grayAlpha10 pb-[1rem];
    }
  }

}
