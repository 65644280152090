.p-menu {
  @apply text-[1.6rem] bg-primary w-[18rem];

  &item-icon {
    @apply text-[1.4rem] text-secondary-blue pr-[1rem];
  }

  &item-link {
    @apply px-[2rem] py-[1rem];
  }
}

.p-submenu-header {
  @apply bg-secondary-darkBlue;
}
