label {
  @apply text-secondary text-[1.6rem];
}

.p-inputtext {
  &:not(.p-dropdown-label):not(.p-dropdown-filter):not(.p-inputtextarea) {
    @apply h-[6.6rem] w-full bg-primary border border-secondary rounded-2xl text-secondary px-[2rem] py-[2.2rem] text-[1.6rem] mb-[1.8rem];

    &.with-icon {
      @apply pl-[3rem]
    }

    &.icon {
      @apply pr-[5.5rem];
    }

    &.no-margin {
      @apply mb-0;
    }

    &.small {
      @apply h-[4rem];
    }

    &::placeholder {
      @apply opacity-0;
    }

    &:focus::placeholder {
      @apply text-secondary text-[1.4rem] opacity-50;
    }

    &:enabled:focus {
      @apply shadow-none border border-secondary-blue text-secondary-blue;
    }

    &.p-filled {
      @apply bg-primary;
    }

    &.ng-touched.ng-invalid, &.ng-untouched.p-filled.ng-invalid {
      @apply border-secondary-red text-secondary-red;
    }

  }
}

.p-inputwrapper {
  @apply w-full;

  &.ng-touched.ng-invalid {
    & + label,
    .p-icon-wrapper {
      @apply text-secondary-red;
    }

    .p-inputtext:enabled:focus {
      @apply border-secondary-red text-secondary-red;
    }

    .p-inputtext,
    .p-dropdown,
    .p-password-input {
      @apply border-secondary-red text-secondary-red;
    }
  }
}

.p-input-icon-right > .p-icon-wrapper {
  @apply top-0 mt-[2.3rem] mr-[1.3rem];
  .p-icon {
    @apply w-[2rem] h-[2rem];
  }
}

.p-float-label {
  @apply block;
  label {
    @apply mt-[-1.8rem] px-[2rem];
  }

  input, textarea {
    &:focus + label,
    &.p-filled + label {
      @apply absolute left-[2.2rem] top-[1.3rem] px-[0.4rem] bg-primary text-[1.2rem] text-secondary;
    }

    &.ng-touched.ng-invalid + label, &.ng-untouched.p-filled.ng-invalid + label {
      @apply text-secondary-red;
    }

    &:focus + label {
      @apply text-secondary-blue;
    }
  }

  textarea + label {
    @apply absolute left-[2.2rem] top-[1.3rem] px-[0.4rem] bg-primary text-[1.2rem] text-secondary;
  }

  .p-inputwrapper-filled {
    ~ label {
      @apply absolute left-[2.2rem] top-[1.3rem] px-[0.4rem] bg-primary text-[1.2rem];
    }
  }

  .p-inputwrapper-focus {
    & + label {
      @apply absolute left-[2.2rem] top-[1.3rem] px-[0.4rem] bg-primary text-[1.2rem] text-secondary-blue;
    }

    .p-icon-wrapper {
      @apply text-secondary-blue;
    }
  }

  .error {
    @apply absolute bottom-[1rem] right-[2rem] text-secondary-red px-[0.4rem] bg-primary text-[1.2rem];
  }
}

.p-checkbox {
  @apply mr-[1rem];
  &-box {
    @apply border border-secondary bg-primary;
  }

  &-icon {
    @apply text-white;
  }
}

.p-element.ng-dirty.ng-invalid {
  .p-checkbox {
    &-box {
      @apply border-secondary-red;
    }

    &-label,
    &-icon {
      @apply text-secondary-red;
    }
  }
}

.p-inputtextarea {
  @apply w-full bg-primary border border-secondary rounded-2xl text-secondary px-[2rem] py-[2.2rem] text-[1.6rem] mb-[1.8rem];
}

.form-group.ng-invalid.ng-dirty {
  .p-float-label:has(> .error) {
    .p-inputtext,
    label {
      @apply border-secondary-red text-secondary-red;
    }
  }
}

.p-icon-field .p-input-icon {
  @apply top-[2rem] mt-0;

  &.top-8 {
    @apply top-[-.8rem];
  }
}

.p-icon-field-right .p-input-icon:last-of-type {
  @apply right-[2rem];
}
