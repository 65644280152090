.p-speeddial {
  @apply relative;
  &-button {
    width: 2rem;
    height: 2rem;
    top: -15px;
  }

  &-direction-left {
    flex-direction: column;
  }
}
