/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/primeng.min.css';
@import 'primeicons/primeicons.css';

@tailwind base;
@tailwind utilities;
@import 'commons/_index.css';
@import 'dark-mode/_index.css';

@layer base {
  @font-face {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/assets/fonts/Mulish.ttf') format('truetype-variations');
  }

  html {
    font-family: 'Mulish', sans-serif;
  }
}

html {
  @apply h-full font-['Mulish'] text-[10px];
}

body {
  @apply m-0 font-['Mulish'];

  &.p-overflow-hidden {
    @apply overflow-auto;
  }

  p {
    @apply text-[1.4rem];
  }
}

.p-divider {
  &-horizontal {
    &:before {
      @apply border-t border-t-grayAlpha10;
    }
  }
}

.p-icon-field .p-input-icon {
  @apply text-[1.5rem] mr-[.4rem] top-[1.5rem]
}

.map-point {
  @apply flex items-center justify-center w-[2rem] h-[2rem] rounded-full text-primary text-[1rem] font-bold;

  &.state-done {
    @apply bg-secondary-green;
  }

  &.state-to_approve {
    @apply bg-secondary-blue;
  }

  &.state-ready {
    @apply bg-gray;
  }

  &.state-on_road {
    @apply bg-secondary-red;
  }
}

.state-done-text {
  @apply text-secondary-green;
}

.state-to_approve-text {
  @apply text-secondary-blue;
}

.state-ready-text {
  @apply text-gray;
}

.state-on_road-text {
  @apply text-secondary-red;
}

.strikethrough {
  position: relative;
}

.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  border-color: inherit;

  -webkit-transform: rotate(-5deg);
  -moz-transform: rotate(-5deg);
  -ms-transform: rotate(-5deg);
  -o-transform: rotate(-5deg);
  transform: rotate(-5deg);
}

