@import '_autocomplete.scss';
@import '_buttons.scss';
@import '_datatable.scss';
@import '_datepicker.scss';
@import '_dialogs.scss';
@import '_dropdowns.scss';
@import '_inputs.scss';
@import '_leaflet.scss';
@import '_listbox.scss';
@import '_menu.scss';
@import '_messages.scss';
@import '_order_list.scss';
@import '_panels.scss';
@import '_sidebar.scss';
@import '_speeddial.scss';
@import '_tabview.scss';
@import '_tags.scss';
@import '_timelines.scss';
@import '_tiredmenu.scss';
@import '_toasts.scss';
@import '_tooltips.scss';

:host {
  --font-size: 10px;
}
