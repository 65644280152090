.p-sidebar {
  @apply shadow-left rounded-tl-[4rem] rounded-bl-[4rem] w-[45rem];

  &-header {
    @apply flex justify-between items-center px-[3rem] pt-[3rem] pb-[2rem];
  }

  &-content {
    @apply p-0;
  }

  h3 {
    @apply text-[2.4rem];
  }
}
