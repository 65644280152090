.p-tabview {
  @apply text-[1.4rem];

  &-nav {
    @apply flex gap-[.5rem];
  }

  a {
    @apply bg-grayAlpha30;
  }

  .p-highlight {
    a {
      @apply bg-[#1f2937];
    }
  }


}
