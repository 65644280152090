.p-tabview {
  @apply text-[1.4rem];

  &-nav {
    @apply flex gap-[.5rem];
  }

  a {
    @apply bg-grayAlpha30;
  }

  .p-highlight {
    a {
      @apply bg-[#1f2937];
    }
  }

  &.table {
    @apply text-[1.8rem];
    a {
      @apply bg-gray/0 px-[3rem] py-[2.5rem] w-[19rem] justify-center;
    }

    .p-highlight {
      border-bottom: 2px solid transparent;
      border-image: linear-gradient(0.25turn, rgb(86, 189, 255), rgb(50, 56, 255), rgba(56, 2, 155, 100));
      border-image-slice: 1;
      width: 100%;

      a {
        @apply bg-gray/0;
      }
    }

    .p-tabview-panels {
      @apply hidden;
    }
  }
}
