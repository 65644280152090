.p-panel {
  &-header {
    @apply bg-primary;
  }

  &-content {
    @apply bg-primary;
  }

  &-toggleable {
    .p-panel-header {
      @apply cursor-pointer;
    }
  }

  &.map-panel {
    .p-panel {
      &-header {
        @apply bg-none text-[1.6rem];
        background-image: radial-gradient(
            circle,
            rgba(15, 22, 32, 1) 0%,
            rgba(23, 33, 48, 1) 100%
        );
      }

      &-content {
        @apply bg-none;
        background-image: radial-gradient(
            circle,
            rgba(15, 22, 32, 1) 0%,
            rgba(23, 33, 48, 1) 100%
        );
      }
    }
  }
}
