.p-datatable {
  @apply bg-primary text-[1.6rem];

  &-header {
    @apply bg-primary;
  }

  table th:first-child {
    @apply rounded-tl-[1rem];
  }

  table th:last-child {
    @apply rounded-tr-[1rem] pr-[2rem];
  }

  &-thead {
    > tr > th {
      @apply bg-secondary-darkBlue border-b border-b-[#96A4B84D] h-[5.6rem] pl-[2rem];
    }

    th {
      &.p-resizable-column {
        @apply border-r border-r-grayAlpha10 mr-[.5rem];
      }

      .th-content {
        @apply flex items-center gap-[1rem];
      }

    }
  }

  &-tbody {
    > tr {
      @apply bg-primary border-b border-b-[#96A4B84D] h-[6.5rem];
    }

    td {
      @apply pl-[2rem];

      &:last-child {
        @apply pr-[2rem];
      }
    }
  }

  .p-selectable-row {
    @apply cursor-default;
  }

  .p-paginator {
    @apply bg-primary text-[1.6rem];
  }

  &-striped {
    .p-datatable-tbody > tr:nth-child(even) {
      background: #1f1f22;
    }
  }
}
