.leaflet-popup-content {
  @apply text-[1.4rem] font-['Mulish'];

  h3 {
    @apply text-[1.8rem] font-bold mb-[.5rem];
  }

  .bold {
    @apply font-bold;
  }

  .label {
    @apply inline-block font-[500] text-[1.6rem] text-gray w-[7rem];
  }

  p {
    @apply my-[.3rem]
  }
}
