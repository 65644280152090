.p-messages {
  @apply text-[1.6rem];

  .p-message {
    &-wrapper {
      @apply py-[.8rem] px-[1.75rem];
    }
  }

  &.no-margin {
    .p-message {
      @apply m-0;
    }
  }
}
