.p-dropdown {
  @apply h-[6.6rem] w-full bg-primary border border-secondary rounded-2xl text-secondary px-[2rem] py-[2rem] mb-[1.8rem];

  &:enabled:focus {
    @apply shadow-none border border-secondary-blue text-secondary-blue;
  }

  &.p-filled {
    @apply bg-primary;
  }

  &.ng-touched.ng-invalid {
    @apply border-secondary-red text-secondary-red;
  }

  .p-inputtext {
    @apply text-[1.6rem];
  }

  .p-inputwrapper {
    @apply text-[1.6rem];

    &-focus {
      @apply border border-secondary-blue;
    }
  }

  &.p-inputwrapper-focus {
    @apply border border-secondary-blue;

    .p-dropdown-label {
      @apply text-secondary-blue;
    }
  }

  &-item {
    @apply p-0 pl-[3rem];

    .caption {
      @apply text-secondary;
    }
  }

  &-panel {
    @apply ml-[-.1rem] mt-[.5rem] rounded-[2.5rem] border border-secondary-blue bg-primary text-[1.6rem] overflow-x-hidden;

    &.panel-elem {
      @apply rounded-[1rem] py-[2.2rem];

      .p-dropdown-item {
        @apply flex h-[4rem] px-[2rem] align-middle items-center;
      }
    }
  }

  &-header {
    @apply py-[3rem] px-[3rem] border-t-secondary rounded-t-[2.5rem] bg-primary;
  }

  &-label {
    @apply flex items-center;
  }

  .flag {
    @apply text-[2.8rem] mr-[1rem];
  }

  &-filter.p-inputtext {
    @apply h-[4rem] p-[1rem] border border-secondary border-opacity-50;
  }
}

.no-head-p {
  .p-dropdown-header {
    @apply pt-0 pb-[1rem];
  }
}
